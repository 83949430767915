/* Import plugins */

import { setupCalendar } from 'v-calendar';
import 'v-calendar/style.css';
import * as ConfirmDialog from 'vuejs-confirm-dialog';

/* Set up plugins */

export default function plugins(app) {
	app.use(setupCalendar, {
		masks: {
			weekdays: 'WWW',
		},
	});

	app.use(ConfirmDialog);
}
