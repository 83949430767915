/* Import router */

import { createRouter, createWebHistory } from 'vue-router';

/* Get previous URL from history state */
function getPreviousURL() {
	if (window.history.state && window.history.state.back) {
		return window.history.state.back;
	}

	if (document.referrer) {
		return document.referrer;
	}

	return null;
}

/* Keep default view alive when navigating to modal */
function keepDefaultView(router, to, from) {
	/* If we came from a SPA page */
	if (from.matched.length > 0) {
		to.matched[0].components.default = from.matched[0].components.default;
	} else {
		/* If we have no real "from", try to find one from the browser history state */
		const previousFrom = router.resolve(getPreviousURL());

		if (previousFrom) {
			/* Use the component from the browser history, if available */
			to.matched[0].components.default =
				previousFrom.matched[0].components.default;
		} else {
			/* Otherwise, use the route specific default parent, or a hardcoded fallback of last resort */
			to.matched[0].components.default =
				to.meta.defaultParent ||
				(() => getComponent(import('js/components/screens/Dashboard.vue')));
		}
	}
}

/* Display loading state while component is being loaded */
function getComponent(promise) {
	App().beginLoading();
	return promise.then((component) => {
		App().finishLoading();
		return component;
	});
}

/* Define routes */
export default function router(pb) {
	const router = createRouter({
		history: createWebHistory(),

		/* Scroll to top for each navigation */
		scrollBehavior() {
			return { top: 0 };
		},

		/* Define routes */
		routes: [
			/* Main view */
			{
				path: '/',
				component: () =>
					getComponent(import('js/components/screens/Dashboard.vue')),
				meta: {
					title: 'Dashboard',
					persist: true,
				},
			},
			{
				path: '/schedule',
				component: () =>
					getComponent(import('js/components/screens/Schedule.vue')),
				meta: {
					title: 'Schedule',
					persist: true,
				},
			},
			{
				path: '/invoicing',
				component: () =>
					getComponent(import('js/components/screens/Invoicing.vue')),
				meta: {
					title: 'Invoicing',
					persist: true,
				},
			},
			{
				path: '/earnings',
				component: () =>
					getComponent(import('js/components/screens/Earnings.vue')),
				meta: {
					title: 'Earnings',
					persist: true,
				},
			},
			{
				path: '/career',
				component: () =>
					getComponent(import('js/components/screens/Career.vue')),
				meta: {
					title: 'Career',
					persist: true,
				},
			},
			{
				path: '/insights',
				component: () =>
					getComponent(import('js/components/screens/Insights.vue')),
				meta: {
					title: 'Insights',
					persist: true,
				},
			},
			{
				path: '/account',
				component: () =>
					getComponent(import('js/components/screens/Account.vue')),
				meta: {
					title: 'Account',
				},
				children: [
					{
						path: '',
						components: {
							account: () =>
								import('js/components/interface/account/UserDetails.vue'),
						},
					},
					{
						path: 'home',
						components: {
							account: () => import('js/components/interface/account/Home.vue'),
						},
					},
					{
						path: 'password',
						components: {
							account: () =>
								import('js/components/interface/account/Password.vue'),
						},
					},
					{
						path: 'subscription',
						components: {
							account: () =>
								import('js/components/interface/account/Subscription.vue'),
						},
					},
					{
						path: 'payment',
						components: {
							account: () =>
								import('js/components/interface/account/PaymentSettings.vue'),
						},
					},
					{
						path: 'invoices',
						components: {
							account: () =>
								import('js/components/interface/account/Invoices.vue'),
						},
					},
					{
						path: 'delete',
						components: {
							account: () =>
								import('js/components/interface/account/Delete.vue'),
						},
					},
				],
			},

			/* Modals */
			{
				path: '/shift/new',
				components: {
					modal: () => import('js/components/interface/shift/Create.vue'),
				},
				meta: {
					title: 'Create new shift',
					defaultParent: () => import('js/components/screens/Schedule.vue'),
					keepParent: true,
				},
			},
			{
				path: '/shift/new/:date',
				components: {
					modal: () => import('js/components/interface/shift/Create.vue'),
				},
				meta: {
					title: 'Create new shift',
					defaultParent: () => import('js/components/screens/Schedule.vue'),
					keepParent: true,
				},
			},
			{
				path: '/shift/edit/:id',
				components: {
					modal: () => import('js/components/interface/shift/Create.vue'),
				},
				meta: {
					title: 'Edit shift',
					defaultParent: () => import('js/components/screens/Schedule.vue'),
					keepParent: true,
				},
			},
			{
				path: '/block/new',
				components: {
					modal: () => import('js/components/interface/block/Create.vue'),
				},
				meta: {
					title: 'Block time out',
					defaultParent: () => import('js/components/screens/Schedule.vue'),
					keepParent: true,
				},
			},
			{
				path: '/block/new/:date',
				components: {
					modal: () => import('js/components/interface/block/Create.vue'),
				},
				meta: {
					title: 'Block time out',
					defaultParent: () => import('js/components/screens/Schedule.vue'),
					keepParent: true,
				},
			},
			{
				path: '/block/edit/:id',
				components: {
					modal: () => import('js/components/interface/block/Create.vue'),
				},
				meta: {
					title: 'Block time out',
					defaultParent: () => import('js/components/screens/Schedule.vue'),
					keepParent: true,
				},
			},

			/* Auth */
			{
				name: 'Login',
				path: '/login',
				component: () =>
					getComponent(import('js/components/screens/Login.vue')),
			},
		],
	});

	router.beforeEach(async (to, from) => {
		/* Keep parent component */
		if (to.meta.keepParent) {
			keepDefaultView(router, to, from);
		}

		/* Refresh auth token */
		try {
			await pb.collection('users').authRefresh();
		} catch {
			/* Redirect to login if we're not logged in */
			pb.authStore.clear();
			if (to.name !== 'Login') {
				return { name: 'Login' };
			}
		}
	});

	router.beforeResolve(async (to) => {
		/* Persist route in cookie if desirable */
		if (to.meta.persist) {
			localStorage.setItem('locumoid_last_route', to.fullPath);
		}

		/* Set tab title */
		document.title = to.meta.title
			? `${to.meta.title} · ${import.meta.env.VITE_APP_NAME}`
			: import.meta.env.VITE_APP_NAME;
	});

	return router;
}
