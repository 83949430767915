export const useNotesStore = defineStore('notes', {
	state: () => ({
		notes: {},
	}),

	getters: {
		getNotesByLocationId(state) {
			return (id) => (id in state.notes ? state.notes[id] : []);
		},
	},

	actions: {
		async fetchLocationNotesFromRemote(locationId) {
			const records = await pb.collection('notes').getList(1, 25, {
				filter: `location = "${locationId}"`,
			});

			this.notes[locationId] = records.items;
			return records.items;
		},

		async addLocationNote(note) {
			try {
				const record = await pb.collection('notes').create(note);
				this.notes[note.location]?.push(record);
				return record;
			} catch (error) {
				return error;
			}
		},

		async deleteLocationNote(note) {
			try {
				await pb.collection('notes').delete(note.id);

				removeFromArrayById(this.notes[note.location], note.id);

				return true;
			} catch (error) {
				return error;
			}
		},
	},
});
