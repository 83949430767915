<template>
	<font-awesome-icon
		:size="size"
		:icon="[combinedStyle, icon]"
	></font-awesome-icon>
</template>

<script setup>
/* Style name to abbreviation */
const styles = {
	solid: 's',
	regular: 'r',
	light: 'l',
	thin: 't',
	duotone: 'd',
};

/* Pick currently selected style, between boolean and string props */
const selectedStyle = computed(
	() =>
		Object.keys(styles).find((style) => properties[style]) ||
		properties.iconStyle,
);

/* Generate abbreviated style class for FontAwesome */
const combinedStyle = computed(
	() =>
		`fa${properties.sharp ? 's' : ''}${styles[selectedStyle.value] || properties.iconStyle || 's'}`,
);

const properties = defineProps({
	/* Icon name */
	icon: {
		type: String,
		required: true,
	},

	/* Size */
	size: {
		type: String,
	},

	/* Style as a prop */
	iconStyle: {
		type: String,
		default: 'solid',
	},

	/* Individual styles as boolean props */
	solid: {
		type: Boolean,
		default: false,
	},
	regular: {
		type: Boolean,
		default: false,
	},
	light: {
		type: Boolean,
		default: false,
	},
	thin: {
		type: Boolean,
		default: false,
	},
	duotone: {
		type: Boolean,
		default: false,
	},
	sharp: {
		type: Boolean,
		default: false,
	},
});
</script>

<style lang="scss" scoped></style>
