export const useShiftsStore = defineStore('shifts', {
	state: () => ({
		shifts: new Map(),
		expand: 'location,location.chain,invoices',
	}),

	getters: {
		all(state) {
			return Array.from(state.shifts.values()).map((shift) => {
				shift.lastAccessed = Date.now();
				return shift;
			});
		},

		getShiftById(state) {
			return (id) => {
				const shift = state.shifts.get(id);
				shift.lastAccessed = Date.now();
				return shift;
			};
		},

		getShiftsByDate(state) {
			return (date) =>
				state.all.filter((shift) =>
					moment(shift.startTime).isSame(moment(date), 'day'),
				);
		},

		getShiftsByWeek(state) {
			return (date) =>
				state.all.filter((shift) =>
					moment(shift.startTime).isSame(moment(date), 'week'),
				);
		},

		getShiftsByMonth(state) {
			return (date) =>
				state.all.filter((shift) =>
					moment(shift.startTime).isSame(moment(date), 'month'),
				);
		},

		getOverlappingShifts(state) {
			return (start, end, id) => {
				return state.all.filter((shift) => {
					return (
						shift.id !== id &&
						moment(shift.startTime).isBefore(end) &&
						moment(shift.endTime).isAfter(start)
					);
				});
			};
		},

		pastShifts(state) {
			return state.all.filter((shift) =>
				moment(shift.endTime).isBefore(moment()),
			);
		},

		invoicedShifts(state) {
			return state.pastShifts.filter(
				(shift) => shift.invoices.length > 0 && 'invoices' in shift.expand,
			);
		},

		uninvoicedShifts(state) {
			return state.pastShifts.filter(
				(shift) => shift.invoices.length === 0 && shift.isInvoiceable,
			);
		},

		shiftsPendingPayment(state) {
			return state.invoicedShifts.filter((shift) =>
				shift.expand.invoices.some((invoice) => !invoice.paid),
			);
		},

		shiftsPaid(state) {
			return state.invoicedShifts.filter((shift) =>
				shift.expand.invoices.every((invoice) => invoice.paid),
			);
		},
	},

	actions: {
		setShifts(newShifts) {
			for (const newShift of ensureArray(newShifts)) {
				this.shifts.set(newShift.id, { ...newShift, lastAccessed: Date.now() });
			}
		},

		setLastAccessed(shifts) {
			for (const shift of ensureArray(shifts)) {
				const shiftId = typeof shift === 'string' ? shift : shift.id;
				if (this.shifts.has(shiftId)) {
					this.getShiftById(shiftId);
				}
			}
		},

		async fetchShiftsFromRemote() {
			const records = await pb.collection('shifts').getFullList({
				expand: this.expand,
			});

			this.setShifts(records);
		},

		async addShift(shift) {
			try {
				const record = await pb.collection('shifts').create(shift, {
					expand: this.expand,
				});
				this.setShifts(record);
				return record;
			} catch (error) {
				return error;
			}
		},

		async updateShift(shift) {
			try {
				const record = await pb.collection('shifts').update(shift.id, shift, {
					expand: this.expand,
				});
				this.setShifts(record);
				return record;
			} catch (error) {
				return error;
			}
		},

		async removeShift(shift) {
			try {
				const record = await pb.collection('shifts').delete(shift.id);
				this.shifts.delete(shift.id);
				return record;
			} catch (error) {
				return error;
			}
		},

		getShiftLength(shift) {
			const difference = moment(shift.endTime).diff(moment(shift.startTime));
			const duration = moment.duration(difference);

			if (shift.lunch && !shift.paysLunch) {
				duration.subtract(shift.lunch, 'minutes');
			}

			const hours = duration.asHours();

			return hours > 0 ? hours : 0;
		},

		getShiftCappedMileage(shift) {
			/* Threshold mileage, if appropriate */
			const mileageLessThreshold =
				Number(shift.mileage) - Number(shift.mileageThreshold);

			/* Return number of billable miles */
			return shift.mileageThreshold
				? mileageLessThreshold < 0
					? 0
					: mileageLessThreshold
				: Number(shift.mileage);
		},

		getShiftMileageRate(shift) {
			/* Process and return mileage rate */
			/* TODO: 45p fallback should take annual mileage into account */
			return shift.mileageRate ? Number(shift.mileageRate) / 100 : 0.45;
		},

		getShiftMileagePrice(shift) {
			/* Work out mileage rate */
			const mileageRate = this.getShiftMileageRate(shift);

			/* Work out billable miles */
			const cappedMileage = this.getShiftCappedMileage(shift);

			/* Calculate appropriate mileage */
			return shift.mileage && shift.paysMileage
				? cappedMileage * mileageRate
				: 0;
		},

		getShiftTotalPrice(shift) {
			/* Get shift base pay */
			const hours = this.getShiftLength(shift) || 0;
			const basepay =
				shift.rateType === 'hour' ? hours * shift.rate : shift.rate;

			/* Get mileage */
			const mileage = this.getShiftMileagePrice(shift);

			/* Calculate final price */
			return basepay + mileage;
		},

		getShiftState(shift) {
			if (moment().isSameOrAfter(shift.startTime)) {
				if (moment().isSameOrBefore(shift.endTime)) {
					return 'active';
				}

				return 'past';
			}

			return 'future';
		},
	},
});
