/* eslint-disable unicorn/prefer-query-selector */

export default function chat() {
	(function (d, t) {
		const BASE_URL = 'https://support-web.taikadesign.app';
		const g = d.createElement(t);
		const s = d.getElementsByTagName(t)[0];
		g.src = BASE_URL + '/packs/js/sdk.js';
		g.defer = true;
		g.async = true;
		s.parentNode.insertBefore(g, s);
		g.addEventListener('load', () => {
			window.chatwootSDK.run({
				websiteToken: 'jrhvTC4CoDa16z8TrdvxiNX8',
				baseUrl: BASE_URL,
			});
		});
	})(document, 'script');
}
