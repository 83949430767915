/* Import FontAwesome */

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { all } from '@awesome.me/kit-d81f33013c/icons';

/* Set up app */

export default function icons(app) {
	/* Set up imported icons */
	library.add(...all);

	/* Register component */
	app.component('font-awesome-icon', FontAwesomeIcon);
}
