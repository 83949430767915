import * as Sentry from '@sentry/vue';

export default function monitoring(app) {
	if (import.meta.env.PROD) {
		Sentry.init({
			app,
			dsn: import.meta.env.VITE_SENTRY_DSN,
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration(),
			],
			tracesSampleRate: 1,
			tracePropagationTargets: [/^\//],
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1,
		});
	}
}
