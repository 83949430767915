<template>
	<div>
		<router-link to="/account" class="navbar-item"> Account </router-link>
		<a class="navbar-item" @click.prevent="logout()"> Log out </a>
	</div>
</template>

<script setup>
/* Get Vue methods */

const router = useRouter();

const emit = defineEmits(['close']);

/* Set up methods */

const logout = function () {
	/* Clear PB auth */
	pb.authStore.clear();

	/* Clear stores */
	User().$reset();
	Shifts().$reset();
	Notes().$reset();

	/* Clear Chatwoot info */
	window.$chatwoot?.reset();

	/* Close menu */
	emit('close');

	/* Go away */
	router.push('/login');
};
</script>

<style lang="scss" scoped></style>
