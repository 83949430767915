/* eslint-disable camelcase */

export default function setChatUser(user) {
	window.addEventListener('chatwoot:ready', () => {
		window.$chatwoot?.setUser(user.id, {
			name: `${user.firstName} ${user.lastName}`,
			email: user.email,
			country_code: 'GB',
			city: user.city,
		});

		window.$chatwoot?.setCustomAttributes({
			gphc_number: user.gphcNumber,
		});
	});
}
