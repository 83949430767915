/* eslint-disable import/no-unassigned-import */

import moment from 'moment';
import 'moment/locale/en-gb';

export default function loadMoment() {
	moment.defineLocale('en-abbr', {
		relativeTime: {
			future: 'in %s',
			past: '%s ago',
			s: '1 sec',
			ss: '%d secs',
			m: '1 min',
			mm: '%d mins',
			h: '1 hr',
			hh: '%d hrs',
			d: '1 day',
			dd: '%d days',
			M: '1 mo',
			MM: '%d mos',
			y: '1 yr',
			yy: '%d yrs',
		},
	});

	moment.locale('en-gb');
}
