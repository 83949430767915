<template>
	<div class="level is-mobile">
		<div class="level-left">
			<div class="level-item is-relative">
				<span class="title is-ghost">Loading...</span>
			</div>
		</div>

		<div class="level-right">
			<span class="button is-ghost">Loading</span>
		</div>
	</div>

	<div class="box is-ghost"></div>

	<article class="content is-ghost">
		<p></p>
		<p></p>
		<p></p>
		<p></p>
	</article>
</template>

<script setup></script>

<style lang="scss" scoped>
.is-ghost:not(.content),
.content.is-ghost p {
	color: transparent !important;
	background: $grey-lightest;
	box-shadow: none !important;
	animation: loading-blink 1.5s infinite linear;

	&:not(.box) {
		border-radius: $radius;
	}

	&.box {
		min-height: 100px;
	}
}

.content.is-ghost p {
	height: 1rem;

	&:last-child {
		width: 40%;
	}
}

@keyframes loading-blink {
	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}
}
</style>
