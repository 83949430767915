export const useAppStore = defineStore('app', {
	state: () => ({
		isLoading: false,
	}),

	actions: {
		beginLoading() {
			this.isLoading = true;
		},
		finishLoading() {
			this.isLoading = false;
		},
	},
});
