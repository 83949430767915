<template>
	<div class="app">
		<div class="container">
			<layout-header />

			<main class="section">
				<router-view v-slot="{ Component }">
					<keep-alive exclude="Login">
						<component :is="App().isLoading ? Loading : Component" />
					</keep-alive>
				</router-view>
			</main>
		</div>

		<router-view v-slot="{ Component }" name="modal">
			<div
				class="modal-blanket"
				:class="{ 'is-active': Component }"
				@click.self="router.go(-1)"
			>
				<div class="modal">
					<div class="box">
						<Transition name="placeholder">
							<component :is="Component" :key="$route.fullPath" />
						</Transition>
					</div>
				</div>
			</div>
		</router-view>

		<DialogsWrapper />
	</div>
</template>

<script setup>
import Loading from 'js/components/interface/Loading.vue';

/* Get router */
const router = useRouter();

/* Set up lifecycle events */
onMounted(() => {
	if (pb.authStore?.isValid) {
		/* Set local store */
		User().$patch({
			data: pb.authStore?.model,
		});

		/* Set support chat user data */
		setChatUser(User().data);

		/* Load shifts */
		Shifts().fetchShiftsFromRemote();

		/* Load blocks */
		Blocks().fetchBlocksFromRemote();
	}
});
</script>

<style lang="scss">
.app > .container {
	padding-top: 9.25rem;

	@include mobile {
		padding-bottom: 8rem;
	}

	@include touch {
		padding-top: 5rem;
	}

	@include desktop {
		&::before {
			content: '';
			height: 3rem;
			background: $white;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 10;
		}
	}
}

.app > .modal-blanket {
	position: fixed;
	inset: 0;
	z-index: 99999999999;
	background-color: rgba($text, 0.5);
	opacity: 0;
	pointer-events: none;
	transition: 0.2s opacity ease-in-out;

	& > .modal {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: $tablet;
		max-height: 100vh;

		& > .box {
			margin: 1rem;
			padding: 2rem;
			transition: 0.2s transform ease-in-out;
			transform: translateY(50px);
		}
	}

	&.is-active {
		opacity: 1;
		pointer-events: auto;

		& > .modal > .box {
			transform: translateY(0);
		}
	}
}
</style>
