export const useUserStore = defineStore('user', {
	state: () => ({
		data: {},
	}),

	actions: {
		async updateDetails(details) {
			try {
				const record = await pb
					.collection('users')
					.update(this.data.id, details);
				this.data = record;
				return record;
			} catch (error) {
				return error;
			}
		},
	},
});
